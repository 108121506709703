<template>
  <div class="block__subtitle mb-40">{{ title }}</div>
  <form class="form" @submit.prevent="onSubmit">
    <div class="form__inner">
      <div class="form__subtitle">Блок управления техническими работами</div>
      <div class="form__text mt-30">Сформируйте предупреждения о проведении технических работ.</div>

      <VCheckbox
        id="status"
        name="status"
        class-form="form-checkbox mt-50 mb-20"
        is-switch
        v-model="formData.status"
      >
        <template #default="slotProps">
          <label :for="slotProps.for" :class="slotProps.class">
            Закрыть СЭТ для проведения технических работ
          </label>
        </template>
      </VCheckbox>

      <VInput
        id="title"
        name="title"
        placeholder="Причина закрытия*"
        :class-input="['input input-placeholder-black']"
        v-model="formData.title"
        :errors="v$.title.$errors"
        :server-errors="serverErrors.value?.title"
        @blur="validateField"
        @input="validateField"
      />

      <VTextarea
        id="description"
        name="description"
        v-model="formData.description"
      />

      <div class="form__buttons form__buttons-setting">
        <button type="submit" class="btn btn-primary btn-bg-green">Сохранить</button>
        <button type="button" class="btn btn-primary btn-bg-transparent" >Отмена</button>
      </div>
    </div>

    <teleport to="body">
      <transition>
        <VModal
          @close="handleModal"
          v-if="showVModal"
          confirm
          confirmQuestion="Вы точно хотите приостановить работу системы?"
          max-width-class="width-620"
        />
      </transition>
    </teleport>
  </form>
</template>

<script>
import VInput from '@/components/ui/form/VInput'
import VModal from '@/components/ui/modal/VModal'
import VCheckbox from '@/components/ui/form/Checkbox/VCheckbox'
import VTextarea from '@/components/ui/form/VTextarea'
import { useSettingWork } from '@/use/Form/Admin/useSettingWork'

export default {
  props: {
    title: {
      type: String,
      required: true
    }
  },
  setup () {
    return {
      ...useSettingWork()
    }
  },
  components: {
    VModal,
    VCheckbox,
    VInput,
    VTextarea
  }
}
</script>
