import { reactive } from 'vue'
import { useForm } from '../../Base/useForm'
import { requiredValid } from '@/utils/validator/fields'
import { useStore } from 'vuex'
import { settingWorks } from '@/http/settingApi'
import useVModal from '@/use/Base/useVModal'

export function useSettingWork () {
  const store = useStore()

  const { showVModal, openVModal, closeVModal } = useVModal()

  const formData = reactive({
    status: false,
    title: '',
    description: ''
  })

  const rules = {
    title: requiredValid
  }

  const serverErrors = reactive({})

  const handleModal = async (boolean) => {
    serverErrors.value = ''
    if (boolean) {
      try {
        const { data } = await settingWorks(formData)
        localStorage.setItem('accessString', data.data.access_string)
        await store.dispatch('setAlert', {
          type: 'success',
          description: 'Технические работы на сервере включены'
        })
      } catch (e) {
        await store.dispatch('setAlert', {
          type: 'error',
          description: e.response.data.message
        })
        if (e.response.data.errors) {
          serverErrors.value = e.response.data.errors
        }
      }
    }
    closeVModal()
  }

  const formAction = async () => {
    openVModal()
  }

  return {
    ...useForm(rules, formData, formAction),
    handleModal,
    showVModal,
    serverErrors
  }
}
